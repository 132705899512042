import React from 'react';
import YouTube from 'react-youtube';

export default class YouTubeComponente extends React.Component {
  render() {
    const opts = {
        height: '390',
       width: '640',// Define largura como zero
    playerVars: {
      autoplay: 1,       // Reproduz automaticamente
      controls: 0,       // Remove os controles do player
      modestbranding: 1, // Remove o logo do YouTube
      showinfo: 0,       // Oculta informações do vídeo
    },
    //   height: '390',
    //   width: '640',
    //   playerVars: {
    //     // https://developers.google.com/youtube/player_parameters
    //     autoplay: 1,
    //     controls: 0
    //   },
    };

    return <YouTube videoId="cApJoCKHkl0" opts={opts} onReady={this._onReady} />;
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

