import React, {useState, useEffect} from 'react';
import './App.css';

export default function(props){

    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState();

    const enviarAudioPlay = (teste) => {
        props.onClick(teste);
      };

      const [audioData, setAudioData] = useState([]);
      const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        
        console.log(props.rssfeed);
        
        console.log('dfdfsdfsdfs');
        try {
            const response = fetch(props.rssfeed)
                .then(response => response.text())
                .then(str => new window.DOMParser().parseFromString(str, 'text/xml'))
                .then(data => {  
                         
                    // console.log(data);                                
                    const itemList = data.querySelectorAll('item');
                    console.log(itemList);
            
                    const items=[];
                    itemList.forEach(async el => {

                        const audio = new Audio(el.querySelector('enclosure').getAttribute('url'));
                        const audioDuration = audio.duration;
                        await new Promise((resolve) => {
                            audio.onloadedmetadata = () => resolve();
                        });
                        
                        if(el.querySelector('pastor').innerHTML.indexOf(props.busca) >= 0){
                           
                        items.push({
                        pubDate: el.querySelector('pubDate').textContent,
                        title: el.querySelector('title').innerHTML,
                        pastor: el.querySelector('pastor').innerHTML,
                        mp3: el.querySelector('enclosure').getAttribute('url'),
                        totaltime: audio.duration,
                        description: el
                            .querySelector('description')
                            .textContent.replace(/(<([^>]+)>)/gi, ''),
                        });
                    }
                    setRowData([...items]);
                    });
                    
                });

                if (!response.ok) {
                    throw new Error(`Erro de Rede: ${response.status}`);
                  }

                  const xmlText = response.text();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, "application/xml");

            } catch (error) {
                console.error("Erro ao buscar XML:", error);
              }
    },[props.rssfeed, props.busca]);

    const formatTime = (time) => {  
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        // console.log(time);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
      };

    const Episodes = () => {

        console.log(rowData);

        return (

        // <div>
        rowData.map((item) => (
            <button onClick={() => enviarAudioPlay(item)}>
            <div className="episode">
                 {/* <audio controls preload="none" style={{height: '2em', verticalAlign:'middle'}}>
                    <source src={item.mp3} type="audio/mpeg" />
                 </audio> */}
                   <h3>{item.title}</h3>
                   <h4>{item.pastor}</h4>
                   <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}><p>Data: {item.pubDate}</p><p>Duração: {formatTime(item.totaltime)}</p></div>
                 </div>
                 </button>
          
        ))
      /* </div> */
        )

        

        



    }
    let count = 0;
    return (
        <Episodes />
        // rowData.forEach(el => {
        //     console.log(el.mp3);
            
        //     console.log(count = count+1);
            
        //     // <div className="episode">
        //     //     <audio controls preload="none" style={{height: '2em', verticalAlign:'middle'}}>
        //     //         <source src="https://appsesites.com.br/tib/podcast/pregacao.mp3" type="audio/mpeg" />
        //     //     </audio>
        //     //       <h3>Episódio 1: Introdução</h3>
        //     //       <p>Duração: 30 min</p>
        //     //     </div>

        // })

    )

}