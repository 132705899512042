import './App.css';
import React, {useState, useEffect, useRef} from 'react';
import {PodcastGrid} from './PodcastGrid';
import "./App.css";
import ComponenteAudio from './ComponenteAudio';
import Howl from "react-howler";
import Modal from './Modal';
import Menu from "./Menu";
import AudioShare from './AudioShare';

function App() {

  const [busca, setBusca] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);


  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault(); // Evita o prompt automático do navegador
      setDeferredPrompt(e); // Armazena o evento para uso posterior
      setIsInstallable(true); // Ativa o botão de instalação
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Mostra o prompt de instalação
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        console.log('Usuário aceitou a instalação.');
      } else {
        console.log('Usuário recusou a instalação.');
      }
      setDeferredPrompt(null); // Limpa o evento
    }
  };





  const [audiocarregado, setAudiocarregado] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null); // Mantém o áudio selecionado
  const audioRef = useRef(null); // Referência para o elemento <audio>

  const playAudio = (audioSrc) => {
    // Atualiza o áudio atual
    setCurrentAudio(audioSrc);
    // Reinicia o player após atualizar o src
    if (audioRef.current) {
      audioRef.current.load(); // Recarrega o áudio
      audioRef.current.play().catch((err) => console.error("Erro ao reproduzir:", err));
    }
  };

 // Função para converter segundos em mm:ss
 const formatTime = (time) => {  
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  // console.log(time);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};


const AdvancedAudioPlayer = () => {

  const [isPlaying, setIsPlaying] = useState(audiocarregado);


  const [currentTime, setCurrentTime] = useState(0); // Tempo atual do áudio
  const [duration, setDuration] = useState(0); // Duração total do áudio

  const audioRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1);

  // const handleTimeUpdate = () => {
  //   if (audioRef.current) {
  //     setCurrentTime(audioRef.current.currentTime); // Atualiza o tempo atual
  //   }
  // };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    if (audioRef.current) {
      // const currentTime = audioRef.current.currentTime;
      // const duration = audioRef.current.duration;
      setProgress((currentTime / duration) * 100);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration); // Obtém a duração total do áudio
    }
  };

  // const [duration, setDuration] = useState(0);

  const handlePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  // const handleTimeUpdate = () => {
  //   const current = audioRef.current.currentTime;
  //   const duration = audioRef.current.duration;
  //   setProgress((current / duration) * 100);
  // };

  const handleSeek = (event) => {
    
    const seekTo = parseFloat(event.target.value);
    console.log((event.target.value / 100) * audioRef.current.duration);
      setProgress(seekTo);
      if (audioRef.current) {
        audioRef.current.currentTime = (event.target.value / 100) * audioRef.current.duration;
        // audioRef.current.seek(seekTo);
      }
    // const seekTime = (event.target.value / 100) * audioRef.current.duration;
    // audioRef.current.currentTime = seekTime;
    // setProgress(event.target.value);
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  const handleStop = () => {
    setIsPlaying(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reinicia o áudio
    }
  };

  return (
    <div style={{ 
      display:'flex',
      flexDirection:'column',
      maxWidth: "100%", 
      justifyContent:'center',
      alignItems:'center',
      margin: "0 auto", 
      textAlign: "center" }}>
      
      <audio
        ref={audioRef}
        src={currentAudio}
        autoPlay
        onLoad={() => setDuration(audioRef.current.duration())}
        
        onLoadedMetadata={handleLoadedMetadata} // Obtém a duração do áudio
        // controls
        onTimeUpdate={handleTimeUpdate}
      />

<p className='title_audio_executando'>{itemPlay.title}</p>
<div className='buttoncontrols'>
          {/* <button className='audiobutton' onClick={handlePrev}>⏮️</button> */}
          <button className='audiobutton' onClick={handlePlayPause}>
            {isPlaying ? "⏸️ Pause" : "▶️ Play"}
          </button>
          <button className='audiobutton' onClick={handleStop}>⏹️ Stop</button>
          {/* <button className='audiobutton' onClick={handleNext}>⏭️</button> */}
        </div>


      {/* <div>
        <button onClick={handlePlayPause}>Play / Pause</button>
      </div> */}
      <div style={{width:'100%'}}>
        <input
          type="range"
          min="0"
          max="100"
          value={progress}
          onChange={handleSeek}
          style={{ width: "100%" }}
        />
      </div>
      {/* <div>
        <label>Volume: </label>
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          value={volume}
          onChange={handleVolumeChange}
        />
      </div> */}

      <div className='progress'>
      <div style={{ margin: "0px 0" }}>
          {/* <input
            type="range"
            min="0"
            max={duration || 0}
            value={progress}
            step="0.1"
            onChange={handleSeek}
            style={{ width: "100%" }}
          /> */}
        </div>
      <p className='title'>
      {/* {currentTime.toFixed(2)} */}
      {formatTime(currentTime.toFixed(2))} / {formatTime(duration)}
        </p>
        </div>
        {/* <AudioShare audio={itemPlay.mp3} /> */}

    </div>
  );
};


  const [audioPlay, setAudioPlay] = useState('');
  const [itemPlay, setItemPlay] = useState(null);

  const receberAudio = (valor) => {
    console.log(valor);
    setAudioPlay(valor);
  };

  const [rowData, setRowData] = useState([]);


  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [submenuvisible, setSubmenuvisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
    setSubmenuvisible(!submenuvisible);
  };

  // const [inputFeedUrl, setInputFeedUrl] = useState("http://localhost:3000/feedpregacao.xml");
  const [inputFeedUrl, setInputFeedUrl] = useState("/feedpregacao.xml");
  const [rssFeed, setRssFeed] = useState("");
  const [quickFilter, setQuickFilter] = useState("");
  const [feedUrls, setFeedUrls] = useState(
            [
              // {name: "WebRush", url:"http://localhost:3000/feedpregacao.xml"},
              {name: "WebRush", url:"/feedpregacao.xml"},
              {name: "The Evil Tester Show", url:"https://feed.pod.co/the-evil-tester-show"},
              {name: "The Change log", url:"https://changelog.com/podcast/feed"},
              {name: "JS Party", url: "https://changelog.com/jsparty/feed"},
              {name: "Founders Talk", url:"https://changelog.com/founderstalk/feed"},
            ]
  );

  const handleLoadFeedClick = ()=>{
    const inputRssFeed = document.getElementById("rssFeedUrl").value;
    setRssFeed(inputRssFeed);
  }

  const handleFilterChange = (event)=>{
    setQuickFilter(event.target.value);
  }

  useEffect(()=>{
        
   
    try {
        const response = fetch("/feedpregacao.xml")
            .then(response => response.text())
            .then(str => new window.DOMParser().parseFromString(str, 'text/xml'))
            .then(data => {  
                     
                // console.log(data);                                
                const itemList = data.querySelectorAll('item');
                console.log(itemList);
        
                const items=[];
                itemList.forEach(el => {
                    console.log(el.querySelector('pubDate').textContent);
                    items.push({
                    pubDate: new Date(el.querySelector('pubDate').textContent),
                    title: el.querySelector('title').innerHTML,
                    mp3: el.querySelector('enclosure').getAttribute('url'),
                    description: el
                        .querySelector('description')
                        .textContent.replace(/(<([^>]+)>)/gi, ''),
                    });
                });
                setRowData(items)
            });

            if (!response.ok) {
                throw new Error(`Erro de Rede: ${response.status}`);
              }

              const xmlText = response.text();
const parser = new DOMParser();
const xmlDoc = parser.parseFromString(xmlText, "application/xml");

        } catch (error) {
            console.error("Erro ao buscar XML:", error);
          }
},[]);

const [contador, setContador] = useState(0);

const handleClick = (episode) => {
  // setContador(contador + 1);
  console.log(`Botão clicado! Contador: ${episode}`);
};

const atualizarValor = (novoValor) => {
  setItemPlay(novoValor);
  openModal();
  playAudio(novoValor.mp3);
  setAudiocarregado(true);
  console.log(novoValor.title);
  console.log(`Botão clicado! ${novoValor.title}`);
};

const menuItems = [
  {
    label: "Home",
    link: "/home",
  },
  {
    label: "Pregador",
    children: [
      { label: "Pr. Geraldo Geremias", link: "/services/web-development" },
      { label: "Pr. Wendell", link: "/services/app-development" },
    ],
  },
  // {
  //   label: "About Us",
  //   link: "/about-us",
  // },
 
];





const receberDados = (valor) => {
  console.log(valor);
  setBusca(valor);
  toggleSidebar();
};

  return (

    <div className="app">
      {/* Header */}
      <header className="header">
        <h1>Podcast TIB Macaé</h1>
      </header>

      {/* Sidebar */}
      <aside className={`sidebar ${isSidebarVisible ? "visible" : ""}`}>
        <button className="close-btn" onClick={toggleSidebar}>
          ✖
        </button>
        <Menu submenuvisible={submenuvisible} onChange={receberDados} enviarDado={receberDados} items={menuItems} />
        <ul>
          {/* <li ><a href='#'>Home</a></li>
          <li>Podcasts</li>
          <li>Playlists</li>
          <li>Favoritos</li> */}
          {/* <li><a href='#' onClick={handleInstallClick}>Instalar o App</a></li> */}
          {/* <div style={{ textAlign: 'center', padding: '20px' }}>
    <h1>Instale nosso aplicativo!</h1> */}
    {/* {isInstallable ? (
      <button onClick={handleInstallClick} style={{ }}>
        Instalar App
      </button>
    ) : (
      <p>O aplicativo não está disponível para instalação.</p>
    )} */}
  {/* </div> */}
          <div>
        {/* <label htmlFor="rssFeedUrl">RSS Feed URL:</label>
        <input type="text" id="rssFeedUrl" name="rssFeedUrl"  style={{width:"50%"}} 
                value={inputFeedUrl}
                onChange={(event)=>setInputFeedUrl(event.target.value)}/>
        <button onClick={handleLoadFeedClick}>Load Feed</button> */}
      </div>
        </ul>
      </aside>

      {/* Floating Button */}
      <button className="toggle-btn" onClick={toggleSidebar}>
        ☰
      </button>

      {/* Main Content */}
      <main className="content">
        <h2>Últimos Episódios</h2>        
       <div className="episodes">


        <ComponenteAudio busca={busca} onClick={atualizarValor} enviarAudio={receberAudio}  rssfeed = "/feedpregacao.xml"/>

        
      <Modal isOpen={isModalOpen} onClose={closeModal}>
      {itemPlay ? 



      <div style={{width:'100%'}} className="">
        <AdvancedAudioPlayer />
                         </div>



: ''}
        
        {/* <h2>Conteúdo do Modal</h2>
        <p>Este é um exemplo de modal em React!</p> */}
      </Modal>

        {/* <div>
      {rowData.map((item) => (
        <div key={item}>{item.mp3}</div>
      ))}
    </div> */}





             {/* <PodcastGrid rssfeed = {rssFeed}
                     height="500px" width="100%"     
                     quickFilter = {quickFilter}   
      ></PodcastGrid> */}
          {/* <div className="episode">
          <audio controls preload="none" style={{height: '2em', verticalAlign:'middle'}}>
              <source src="https://appsesites.com.br/tib/podcast/pregacao.mp3" type="audio/mpeg" />
          </audio>
            <h3>Episódio 1: Introdução</h3>
            <p>Duração: 30 min</p>
          </div>
          <div className="episode">
            <h3>Episódio 2: React Avançado</h3>
            <p>Duração: 45 min</p>
          </div>
          <div className="episode">
            <h3>Episódio 1: Introdução</h3>
            <p>Duração: 30 min</p>
          </div>
          <div className="episode">
            <h3>Episódio 2: React Avançado</h3>
            <p>Duração: 45 min</p>
          </div>
          <div className="episode">
            <h3>Episódio 1: Introdução</h3>
            <p>Duração: 30 min</p>
          </div>
          <div className="episode">
            <h3>Episódio 2: React Avançado</h3>
            <p>Duração: 45 min</p>
          </div> */}
        </div>
      </main>

      

      {/* Player */}
      {/* <footer className="player">
        {itemPlay ? 
      <div style={{width:'100%'}} className="">
        <AudioPlayer />
      
                 </div>
: ''}
        
      </footer> */}
      {/* <div>
         <label htmlFor="podcasts">Choose a podcast:</label>
         <select name="podcasts" id="podcasts" value={inputFeedUrl}
              onChange={(event)=>setInputFeedUrl(event.target.value)}>
              {feedUrls.map((feed) =>
                <option value={feed.url} key={feed.url}
                >{feed.name}</option>)}
        </select>
      </div>
      <div>
        <label htmlFor="rssFeedUrl">RSS Feed URL:</label>
        <input type="text" id="rssFeedUrl" name="rssFeedUrl"  style={{width:"50%"}} 
                value={inputFeedUrl}
                onChange={(event)=>setInputFeedUrl(event.target.value)}/>
        <button onClick={handleLoadFeedClick}>Load Feed</button>
      </div>
      <div>
      <label htmlFor="quickfilter">Quick Filter:</label>
        <input type="text" id="quickfilter" name="quickfilter" style={{width:"30%"}} value={quickFilter}
              onChange={handleFilterChange}/>        
      </div> */}
      <div>
        {/* <PodcastGrid rssfeed = {rssFeed}
                     height="500px" width="100%"     
                     quickFilter = {quickFilter}   
      ></PodcastGrid> */}
      </div>
      
    </div>
    

    



    // <div className="App">
    //   <h1>Podcast Player</h1>
    //   <div>
    //     <label htmlFor="podcasts">Choose a podcast:</label>
    //     <select name="podcasts" id="podcasts" value={inputFeedUrl}
    //           onChange={(event)=>setInputFeedUrl(event.target.value)}>
    //           {feedUrls.map((feed) =>
    //             <option value={feed.url} key={feed.url}
    //             >{feed.name}</option>)}
    //     </select>
    //   </div>
    //   <div>
    //     <label htmlFor="rssFeedUrl">RSS Feed URL:</label>
    //     <input type="text" id="rssFeedUrl" name="rssFeedUrl"  style={{width:"50%"}} 
    //             value={inputFeedUrl}
    //             onChange={(event)=>setInputFeedUrl(event.target.value)}/>
    //     <button onClick={handleLoadFeedClick}>Load Feed</button>
    //   </div>
      // <div>
      // <label htmlFor="quickfilter">Quick Filter:</label>
      //   <input type="text" id="quickfilter" name="quickfilter" style={{width:"30%"}} value={quickFilter}
      //         onChange={handleFilterChange}/>        
      // </div>
    //   <div>
    //     <PodcastGrid rssfeed = {rssFeed}
    //                  height="500px" width="100%"     
    //                  quickFilter = {quickFilter}   
    //   ></PodcastGrid>
    //   </div>
    // </div>
  );
}

export default App;